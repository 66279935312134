import React from 'react';
import MaterialIcon from 'material-icons-react';

export default class EventDetailsPurchaseTableRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      quantity: 0,
      inventoryId: this.props.inventory.id
    }
    console.log(props.inventory)
    this.incrQuantity = this.incrQuantity.bind(this);
    this.decrQuantity = this.decrQuantity.bind(this);
    this.changeQuantity = this.changeQuantity.bind(this);
    this.handleQuantityChange = this.props.handleQuantityChange.bind(this);
  }

  get maxQty() {
    return this.props.inventory.quantity_limit <= this.props.inventory.quantity_available ? this.props.inventory.quantity_limit : this.props.inventory.quantity_available;
  }

  resetQuantity() {
    this.setState({quantity: 0})
    var minus = document.getElementById('minusIcon' + this.props.inventory.id)
    if (minus) { minus.style.opacity = 0; }
  }

  incrQuantity(event) {
    var newQty = this.state.quantity + 1
    var qtyAvail = this.props.inventory.quantity_available
    const maxQty = this.maxQty;
    if (newQty > maxQty) { newQty = maxQty }
    this.setState({quantity: newQty})
    var plus = document.getElementById('plusIcon' + this.state.inventoryId)
    var minus = document.getElementById('minusIcon' + this.state.inventoryId)
    if (newQty === maxQty) {
      if (plus) { plus.style.opacity = 0 }
    } else {
      if (plus) { plus.style.opacity = 1 }
    }
    if (newQty === 0) {
      if (minus) { minus.style.opacity = 0 }
    } else {
      if (minus) { minus.style.opacity = 1 }
    }
    this.handleQuantityChange({
      quantity: newQty,
      inventory_id: this.state.inventoryId
    })
  }

  decrQuantity(event) {
    var newQty = this.state.quantity - 1
    if (newQty < 0) { newQty = 0 }
    this.setState({quantity: newQty})
    var plus = document.getElementById('plusIcon' + this.state.inventoryId)
    var minus = document.getElementById('minusIcon' + this.state.inventoryId)
    const maxQty = this.maxQty;
    if (newQty === maxQty) {
      if (plus) { plus.style.opacity = 0 }
    } else {
      if (plus) { plus.style.opacity = 1 }
    }
    if (newQty === 0) {
      if (minus) { minus.style.opacity = 0 }
    } else {
      if (minus) { minus.style.opacity = 1 }
    }
    this.handleQuantityChange({
      quantity: newQty,
      inventory_id: this.state.inventoryId
    })
  }

  changeQuantity(event) {
    // var newQty = event.target.value
    // if (newQty > 5) { newQty = 5}
    // if (newQty < 1) { newQty = 0}
    this.setState({quantity: event.target.value})
    this.handleQuantityChange({
      quantity: event.target.value,
      inventory_id: this.state.inventoryId
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.inventory !== this.props.inventory) {
      this.resetQuantity()
      this.setState({
        inventoryId: this.props.inventory.id
      })
      if (this.state.quantity === 0) {
        var minus = document.getElementById('minusIcon' + this.props.inventory.id)
        if (minus) { minus.style.opacity = 0; }
      }
    }
  }

  componentDidMount() {
    if (this.state.quantity === 0) {
      var minus = document.getElementById('minusIcon' + this.props.inventory.id)
      if (minus) { minus.style.opacity = 0; }
    }
  }
  
  render() {
    return (
      <tr className="EventDetailsTablePurchaseRow">
        <td style={{textAlign:"left"}}>
          { this.props.inventory.type?.name }
          {this.props.inventory?.description && <div style={{ fontSize: '14px', textTransform: 'none', fontWeight: 400, fontFamily: "'Titillium Web', sans-serif", display: (this.props.inventory.sold_out || !this.props.inventory.on_sale) ? 'none' : 'block'}} dangerouslySetInnerHTML={{__html: this.props.inventory?.description_html}}/>}
        </td>
        <td className="right-align">{ this.props.inventory.price_str.replace(",", "") }</td>
        <td className="right-align quantity text-nowrap">
          <div>
            {this.props.inventory.sold_out &&
              <span className="eventUnavailable eventSoldOut">Sold out</span>
            }
            {this.props.inventory.after_sale_end_date &&
              <span className="eventUnavailable eventSoldOut">Sold out</span>
            }
            {this.props.inventory.before_sale_start_date &&
              <span className="eventUnavailable">Not yet available</span>
            }
            {this.props.inventory.on_sale &&
              <div>
                <MaterialIcon
                  icon="add_circle"
                  color='#99abaa'
                  size="tiny"
                  onClick={this.incrQuantity}
                  id={"plusIcon"  + this.state.inventoryId}
                />
                <input type="number"
                    value={this.state.quantity}
                    onChange={this.changeQuantity}
                    className="form-control"
                    id="quantity"
                    aria-describedby="quantity"
                    placeholder="0"
                    min="1"
                    max={this.maxQty}
                    disabled
                  />
                  <MaterialIcon
                    icon="remove_circle"
                    color='#99abaa'
                    size="tiny"
                    onClick={this.decrQuantity}
                    id={"minusIcon" + this.state.inventoryId}
                  />
              </div>
            }
          </div>
        </td>
      </tr>
    );
  }
}
