import React, { Component } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { AWSAlternative, PublicImagePath } from '../../constants/constants';
var Carousel = require('react-responsive-carousel').Carousel;


class EventCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: this.props.activeIndex,
      activeEvent: this.props.events[0],
      events: this.props.events
    };
    this.onChange = this.props.changeHandler.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.goToIndex = this.goToIndex.bind(this)
    this.handleClick = this.props.clickHandler.bind(this)
    this.onSwipeStart = this.props.onSwipeStartProps.bind(this)
    this.onScrollToTickets = this.props.onScrollToTicketsProps.bind(this)
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  handleChange(index, item) {
    if (index !== this.state.activeIndex) {
      var newEvent = this.props.events[index]
      this.setState({
        activeIndex: index,
        activeEvent: newEvent
      });
      this.onChange(index)
    }
  }

  preloadImages() {
    this.state.events.map((event, i) => {
      const img = new Image();
      img.src = event.image;
      return img
    });
  }

  componentDidMount() {
    this.preloadImages();
  }

  render() {
    const slides = this.state.events.map((event, i) => {
      return (
        <div
          key={'eventCarouselItem' + event.id}
          className="eventCarouselItem"
          data-event-id={event.id}
          style={{
            backgroundColor:"black",
            // backgroundImage: 'url('+PublicImagePath + event.image + ') , url('+AWSAlternative+event?.image+'),url(' + event.image + ')',
            backgroundImage: 'url(' + event.image + ')',
          }}
        >
          {/* { event.id !== 491 && <img src={`${PublicImagePath}${event.image}`} alt="" /> } */}
          { event.id !== 491 && <img src={`${event.image}`} alt="" /> }
          { event.id === 491 && <div
             className="eventCarouselItemGlitch"
             style={{
               backgroundImage: 'url(https://thebloxoffice.blob.core.windows.net/scripts/guti_background.png)',
             }}
           ><div
               style={{
                 backgroundImage: 'url(https://thebloxoffice.blob.core.windows.net/scripts/guti_overlay.png)',
               }}
             ></div></div> }
        </div>
      );
    });

    return (
      <Carousel
        showThumbs={false}
        selectedItem={this.state.activeIndex}
        onChange={this.handleChange}
        swipeable={this.props.allowSwipe}
        emulateTouch={true}
        swipeScrollTolerance={150}
        onSwipeStart={this.onSwipeStart}
        onSwipeEnd={this.onScrollToTickets}
        onClickItem={this.handleClick}
        infiniteLoop={false}
        transitionTime={200}
      >
        {slides}
      </Carousel>
    )
  }
}


export default EventCarousel;
