import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import { Formik, Field, Form } from "formik";
import {
  FormField,
  FormEventPicker,
  // FormTicketTypePicker,
  FormDateTimePicker,
  FormPromoCodePicker,
  FormCheckbox,
} from "../../../Components/Forms/Fields";
import {
  addTicketInventory,
  changeTicketInventory,
  deleteTicketInventory,
} from "../../../Lib/RESTBlox";
import ButtonLink from "../../../Components/ButtonLink";
import { useNavigate } from "react-router-dom";
import FormTicketTypePicker from "./components/FormTicketTypePicker/FormTicketTypePicker";

type Props = {
  ticketInventory?: any;
  eventId?: any;
};

const TicketInventoryForm: React.FC<Props> = ({ ticketInventory, eventId }) => {
  const [success, setSuccess] = React.useState(false);
  const [currentTicketInventory, setCurrentTicketInventory] = React.useState<any>(ticketInventory||{});
  const [currentEventId, setEventId] = React.useState<number>();
  const [change, setChange] = React.useState(false);
  const [venue, setVenue] = React.useState<any>();
  const navigate = useNavigate()
  
  const handleAddTicketInventory = (values: any) => {
    addTicketInventory(values)
      .then((json) => {
        setSuccess(true);
        setVenue(json);
        navigate(`/admin/events/edit/${eventId}`)
      })
      .catch((err) => {});
  };

  const handleChangeTicketInventory = (values: any) => {
    if(values?.ticket_type?.value){
      values.ticket_type = values?.ticket_type?.value
    }
    changeTicketInventory(currentTicketInventory.id, values)
      .then((json) => {
        // this.setState({
        //   alert: {
        //     type: "success",
        //     message: "Ticket inventory updated.",
        //   },
        // });
        // this.props.history.push(
        //   "/admin/events/edit/" + currentTicketInventory.event
        // );
        navigate(-1)
      })
      .catch((err) => {
        // this.setState({
        //   alert: {
        //     type: "danger",
        //     message: "There was an error updating the ticket inventory.",
        //   },
        // });
      });
  };

  const handleDeleteTicketInventory = (ticketInventoryId: string) => {
    deleteTicketInventory(ticketInventoryId)
      .then((json) => {
        navigate(-1)
      })
      .catch((err) => {});
  };

  const validate = (values: any) => {
    interface LooseObject {
      [key: string]: any;
    }

    let errors: LooseObject = {};

    if (!values.event) {
      errors.event = 'Required';
    }

    if (!values.ticket_type) {
      errors.ticket_type = 'Required';
    }

    if (!values.quantity) {
      errors.quantity = 'Required';
    }

    if (!values.price) {
      errors.price = 'Required';
    }

    return errors;
  };

  const setInitialValue = (keyName: string) => {
    if (keyName === "event") {
      if ("event" in currentTicketInventory) {
        return currentTicketInventory?.event;
      } else {
        return eventId;
      }
    }

    if (keyName === "quantity_limit") {
      if ("quantity_limit" in currentTicketInventory) {
        return currentTicketInventory?.quantity_limit;
      } else {
        return 9;
      }
    }

    if (keyName === "ticket_type") {
      if ("type" in currentTicketInventory) {
        return {
          value: currentTicketInventory.type?.id,
          label: currentTicketInventory.type?.name
        };
      } else {
        return null;
      }
    }

    if (keyName === "price") {
      if ("price_str" in currentTicketInventory) {
        return currentTicketInventory.price_str
          .replace("$", "")
          .replace(",", "");
      } else {
        return "";
      }
    }

    if (keyName === "sale_start_date") {
      if ("sale_start_date" in currentTicketInventory) {
        if (currentTicketInventory.sale_start_date === null) {
          return null;
        }
        return new Date(currentTicketInventory.sale_start_date);
      } else {
        return null;
      }
    }

    if (keyName === "sale_end_date") {
      if ("sale_end_date" in currentTicketInventory) {
        if (currentTicketInventory.sale_end_date === null) {
          return null;
        }
        return new Date(currentTicketInventory.sale_end_date);
      } else {
        return null;
      }
    }

    if (keyName === "allowed_promo_codes") {
      if ("allowed_promo_codes" in currentTicketInventory) {
        return currentTicketInventory.allowed_promo_codes.map(
          (object: any, i: number) => {
            return {
              value: object.id,
              label: object.code,
            };
          }
        );
      } else {
        return [];
      }
    }

    if (keyName === "secret") {
      if ("secret" in currentTicketInventory) {
        return currentTicketInventory[keyName];
      } else {
        return false;
      }
    }

    if (keyName in currentTicketInventory) {
      return currentTicketInventory[keyName];
    } else {
      return "";
    }
  };

  const setUpState = () => {
    // If we have an eventId in props, then we are adding a new ticket inventory.
    if (eventId) {
      setEventId(eventId);
      setChange(false);
    }

    // If we have a ticketInventory in props, then we are editing an existing ticket inventory.
    if (ticketInventory) {
      if (ticketInventory?.event) {
        setEventId(ticketInventory.event.id);
        setCurrentTicketInventory(ticketInventory);
        setChange(true);
      }
    }
  };

  React.useEffect(() => {
    setUpState();
  }, [ticketInventory]);

  return (
    <div>
      {eventId !== null && (
        <Formik
          initialValues={{
            event: setInitialValue("event"),
            ticket_type: setInitialValue("ticket_type"),
            quantity: setInitialValue("quantity"),
            quantity_limit: setInitialValue("quantity_limit"),
            price: setInitialValue("price"),
            sale_start_date: setInitialValue("sale_start_date"),
            sale_end_date: setInitialValue("sale_end_date"),
            description: setInitialValue("description"),
            allowed_promo_codes: setInitialValue("allowed_promo_codes"),
            secret: setInitialValue("secret"),
            secret_code: setInitialValue("secret_code"),
          }}
          onSubmit={(values, actions) => {
            // if(!values.sale_start_date){ //delete this if continues working fine (thi is NOT REQUIRED WE DON'T WANT TO ADD REQUIRED DATE)
            //   values.sale_start_date = new Date().toISOString().replace("T"," ").substring(0, 19);
            // }
            if (Object.entries(currentTicketInventory).length !== 0) {
              handleChangeTicketInventory(values);
            } else {
              handleAddTicketInventory(values);
            }
            actions.setSubmitting(false);
          }}
          validate={validate}
          render={(props) => (
            <Form>
              <Row>
                <Col xs={12}>
                  <Field
                    label="Event"
                    type="text"
                    name="event"
                    placeholder="Type to select event..."
                    component={FormEventPicker}
                    required={true}
                    disabled={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={3}>
                  <Field
                    label="Ticket Type"
                    type="text"
                    name="ticket_type"
                    placeholder="Select..."
                    component={FormTicketTypePicker}
                    required={true}
                    help="The type of ticket you're making available"
                    disabled={currentTicketInventory?.quantity_sold > 0}
                  />
                </Col>
                 <Col xs={12} md={3}>
                  <Field
                    label="Price"
                    type="text"
                    name="price"
                    placeholder="Price..."
                    component={FormField}
                    required={true}
                    help="In US dollars"
                  />
                </Col>
                <Col xs={6} md={3}>
                  <Field
                    label="Quantity"
                    type="number"
                    name="quantity"
                    placeholder="Quantity..."
                    component={FormField}
                    required={true}
                    help="Number of tickets of this type available for sale"
                  />
                </Col>
                <Col xs={6} md={3}>
                  <Field
                    label="Quantity Limit"
                    type="text"
                    name="quantity_limit"
                    placeholder="Quantity Limit..."
                    component={FormField}
                    required={true}
                    help="Number of tickets of this type a single customer can purchase"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <Field
                    label="Sale start date"
                    type="text"
                    name="sale_start_date"
                    placeholder="Select the date and time..."
                    component={FormDateTimePicker}
                    required={false}
                    help="The date and time this group of tickets will be made available. If blank, they will be available immediately. Use your local time"
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <Field
                    label="Sale end date"
                    type="text"
                    name="sale_end_date"
                    placeholder="Select the date and time..."
                    component={FormDateTimePicker}
                    required={false}
                    help="The date and time this group of tickets will be stop being available. If blank, they will be available indefinitely. Use your local time"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={6}>
                  <label></label>
                  <Field
                    label="Make this inventory secret"
                    type="checkbox"
                    name="secret"
                    component={FormCheckbox}
                    required={false}
                    help="If you make this ticket inventory secret, it will only be available to users who enter the secret code."
                  />
                </Col>
                <Col xs={12} sm={6}>
                  <Field
                    label="Secret code"
                    type="text"
                    name="secret_code"
                    placeholder="Enter code..."
                    component={FormField}
                    required={false}
                    help="If this is a secret inventory, it will only be available to users who enter this code."
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    label="Description"
                    type="textarea"
                    name="description"
                    placeholder="Enter a desctiption..."
                    component={FormField}
                    required={false}
                    help="May contain URLs, emoji, and basic Markdown formatting."
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    label="Allowed Promo Codes"
                    type="text"
                    name="allowed_promo_codes"
                    placeholder="Select promo codes..."
                    component={FormPromoCodePicker}
                    required={false}
                    help="If used, only chosen Promo Codes will be eligible for this Ticket Type. If not used, all Promo Codes associated with the event will be eligible."
                    eventId={"event" in currentTicketInventory? currentTicketInventory.event: eventId}
                  />
                </Col>
              </Row>
              <Row className="buttonRow">
                <Col xs={4} md={3}>
                  {change && (
                    <Button
                      onClick={() =>
                        handleDeleteTicketInventory(currentTicketInventory.id)
                      }
                      block
                      color="danger"
                    >
                      Delete
                    </Button>
                  )}
                </Col>
                <Col className="d-none d-md-block" md={3}></Col>
                <Col xs={4} md={3} className="pr-1">
                  <ButtonLink
                    to={""}
                    onClick={(e:any)=>{navigate(-1)}}
                    text="Cancel"
                    block
                    color="secondary"
                  />
                </Col>
                <Col xs={4} md={3} className="pl-1">
                  <Button
                    className="hvr-grow-shadow"
                    block
                    color="primary"
                    type="submit"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        />
      )}
    </div>
  );
};

export default TicketInventoryForm
