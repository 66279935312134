import React from 'react'

function DummyCarousel(props) {
  return (
    <div className="carousel carousel-slider">
      <div className="slider-wrapper axis-horizontal">
        <ul className="slider animated" style={{backgroundColor:"black"}}>
          <li className="slide selected">
            <div
              key={'eventCarouselItem' + props.event.id}
              className="eventCarouselItem"
              data-event-id={props.event.id}
              style={{
                backgroundImage: 'url(' + props.event.image + ')',
              }}
              onClick = {props.clickHandler}
              onTouchStart = {props.swipeHandler}
            >
                { props.event.id !== 491 && <img src={props.event.image} alt="" /> }
                { props.event.id === 491 && <div
                   className="eventCarouselItemGlitch"
                   style={{
                     backgroundImage: 'url(https://thebloxoffice.blob.core.windows.net/scripts/guti_background.png)',
                   }}
                 ><div
                     style={{
                       backgroundImage: 'url(https://thebloxoffice.blob.core.windows.net/scripts/guti_overlay.png)',
                     }}
                   ></div></div> }
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default DummyCarousel
