import React from "react";
import { Row, Col } from "reactstrap";
import EventPurchaseTable from "../../Components/EventPurchase/EventPurchaseTable";
import { getOrder } from "../../Lib/RESTBlox";
import Loader from "../../Components/Loader";
import Header from "../../Components/Header";
import OrderDetailTicketTable from "../../Components/EventPurchase/OrderDetailTicketTable/OrderDetailTicketTable";
import { useParams } from "react-router-dom";
import PriceTable from "./PriceTable/PriceTable";
import TicketTable from "./TicketTable/TicketTable";
import { PublicImagePath } from "../../constants/constants";
import { Grid } from "@mui/material";
import SectionHeader from "./components/SectionHeader/SectionHeader";
import OrderImage from "./components/OrderDate/OrderDate";

type Props = {};

const OrderDetail: React.FC<Props> = (props) => {
  const [order, setOrder] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const params = useParams();
  const setStateFromNewData = (data: any) => {
    setOrder(data);
    setLoading(false);
  };

  const loadData = (orderId: any) => {
    getOrder(orderId)
      .then((data) => setStateFromNewData(data))
      .catch((err) => console.error(err.toString()));
  };

  React.useEffect(() => {
    loadData(params.id);
  }, []);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: "100%", maxWidth: "1400px", margin: "0 auto" }}
    >
      <Grid
        item
        xs={12}
        lg={6}
        xl={6}
        md={6}
        justifyContent="center"
        container
        style={{ alignContent: "flex-start", height: "80%" }}
      >
        <div className="" style={{marginTop: '20px', marginBottom: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

          {(order && order?.event) && <img
              alt=""
              src={order?.event?.image}
              style={{maxWidth: "95vw", maxHeight: "275px"}}
          />}
        </div>
        <Grid item xs={11} lg={11} xl={11} md={11}>
          <SectionHeader rigthText="Your Order" leftText={order[0]?.number} />
        </Grid>
        <Grid item xs={11} lg={11} xl={11} md={11}>
          {/* <OrderDate order={order} /> */}
        </Grid>
        <Grid item xs={11} lg={11} xl={11} md={11}>
          {/* <EventTitle order={order} /> */}
        </Grid>
        <Grid item xs={11} lg={11} xl={11} md={11} style={{marginBottom: "50px"}}>
          <PriceTable order={order} />
        </Grid>
        {order.discount && (
          <Grid item xs={11} lg={11} xl={11} md={11}>
            {/* <SavedLabel order={order} /> */}
          </Grid>
        )}
        <Grid item xs={11} lg={11} xl={11} md={11}>
          {/* <CardPay order={order} /> */}
        </Grid>
        <Grid item xs={10} lg={10} xl={10} md={10}>
          {/* <OrderMap order={order} /> */}
        </Grid>
        {order[0]?.promo_code_uses?.length > 0 && (
          <Grid item xs={11} lg={11} xl={11} md={11}>
            <SectionHeader rigthText="Promocodes" leftText="Number" />
          </Grid>
        )}
        <Grid item xs={11} lg={11} xl={11} md={11}>
          <SectionHeader
            rigthText="Your Tickets"
            leftText={order[0]?.tickets_requested?.length}
          />
        </Grid>
        <Grid item xs={11} lg={11} xl={11} md={11}>
          <TicketTable order={order} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrderDetail;
