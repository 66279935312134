import React, { useEffect } from "react";
import { refreshToken, getCurrentUser } from "../Lib/RESTAuth";
import { getCurrentWhiteLabel } from "../Lib/RESTBlox";
import "../Stylesheets/main.scss";
import AppStackNavigator from "../route/AppStackNavigator";

function App() {
  const [hasToken, setHasToken] = React.useState(
    localStorage.getItem("token") ? true : false
  );
  const [signedIn, setSignedIn] = React.useState(false); // signedIn: localStorage.getItem('token') ? true : false, // Trying this, think it might be better
  const [user, setUser] = React.useState();
  const [whiteLabel, setWhiteLabel] = React.useState({
    id: 0,
    title: "",
    subdomain: "",
    slogan: "",
    description: "",
    logo: null,
    artists: [],
    promoters: [],
    clubs: [],
    resident_artists: [],
    facebook_pixel_id: undefined,
  });
  const [alert, setAlert] = React.useState({
    type: "",
    message: "",
  });

  const postSignIn = (data: any) => {
    setUser(data.user);
    setSignedIn(true);
    setHasToken(localStorage.getItem("token") ? true : false);
    setAlert({
      type: "success",
      message: "You have sucessfully signed in.",
    });
  };

  const postSignUp = (data: any) => {
    setUser(data.user);
    setSignedIn(true);
    setHasToken(localStorage.getItem("token") ? true : false);
    setAlert({
      type: "success",
      message: "You have sucessfully signed up.",
    });
  };

  const postSignOut = () => {
    setUser(undefined);
    setSignedIn(false);
    setHasToken(localStorage.getItem("token") ? true : false);
    setAlert({
      type: "success",
      message: "You have signed out.",
    });
  };

  const postPasswordChange = () => {
    setAlert({
      type: "success",
      message: "You have sucessfully changed your password.",
    });
  };

  const postPasswordForgot = () => {
    setAlert({
      type: "success",
      message:
        "An email has been sent with instructions on how to recover your password.",
    });
  };

  const postProfileChange = (user: any) => {
    setUser(user);
    setAlert({
      type: "success",
      message: "Your profile has been updated.",
    });
  };

  const handleRefreshToken = () => {
    refreshToken()
      .then((json) => {
        handleGetUser();
      })
      .catch((err) => {
        setHasToken(localStorage.getItem("token") ? true : false);
        setAlert({
          type: "danger",
          message: err.response.errorMessage,
        });
      });
  };

  const userExists = () => {
    return signedIn;
  };

  const userHasAdminAccess = () => {
    if (user) {
      return (user as any)?.profile?.has_admin_access;
    }
    return false;
  };

  const handleGetUser = () => {
    getCurrentUser()
      .then((json) => {
        setHasToken(localStorage.getItem("token") ? true : false);
        setSignedIn(true);
        setUser(json);
      })
      .catch((err) => {
        console.log(err);
        if (err && err.response && err.response.errorMessage) {
          setAlert({
            type: "danger",
            message: err.response.errorMessage,
          });
        }
      });
  };

  useEffect(() => {
    getCurrentWhiteLabel().then((json: any) => {
      setWhiteLabel(json);
      if(!json.id){
        window.location.replace('https://rickrosstickets.com')
      }
    });
    if (hasToken && !user) {
      handleRefreshToken();
    }
  }, []);

  return (
    <AppStackNavigator
      whiteLabel={whiteLabel}
      user={user}
      postSignIn={postSignIn}
      postSignUp={postSignUp}
      postSignOut={postSignOut}
      postPasswordForgot={postPasswordForgot}
      signedIn={signedIn}
      handleGetUser={handleGetUser}
      userExists={userExists}
      userHasAdminAccess={userHasAdminAccess}
      postProfileChange={postProfileChange}
      postPasswordChange={postPasswordChange}
      alert={alert}
    />
  );
}

export default App;
