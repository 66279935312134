import React from "react";
// import { Table } from "reactstrap";
// const Table = require("reactstrap");
import {
  FaRegCheckCircle,
  FaRegCircle,
  FaCreditCard,
  FaTicketAlt,
} from "react-icons/fa";
import { MdMoneyOff } from "react-icons/md";
import ButtonLink from "../Components/ButtonLink";
import EventTile from "../Components/Admin/EventTile";
import GoogleMapComponentWithMarker from "../Components/Maps/Map";
import { createBrowserHistory } from "history";
import { Link } from "react-router-dom";
import routes from "../constants/routes";

const Table = require("reactstrap");
const history = createBrowserHistory();
function moneyFormatter(cell: any, row: any) {
  return "$" + cell;
}

function booleanFormatter(cell: any, row: any) {
  if (cell) {
    return <FaRegCheckCircle />;
  } else {
    return <FaRegCircle />;
  }
}

function quietFormatter(cell: any, row: any) {
  return <span className="quiet">{cell}</span>;
}

function birthdateFormatter(cell: any, row: any) {
  return <span>{row.profile.birthdate_display}</span>;
}

function phoneFormatter(cell: any, row: any) {
  return <span>{row.profile.phone_number}</span>;
}

function emailFormatter(cell: any, row: any) {
  return <span>{row.email}</span>;
}

function detailsFormatter(cell: any, row: any) {
  return (
    <>
      <span>
        <Link
          style={{
            color: "black",
            fontWeight: "bolder",
            textDecoration: "none",
            textTransform: "uppercase",
          }}
          to={`/admin/users/${row.pk}`}
        >
          {row.first_name} {row.last_name}
        </Link>
      </span>
      <span
        style={{
          color: "#99abaa",
          fontSize: ".8rem",
          lineHeight: "1rem",
          textTransform: "none",
          fontWeight: 300,
          marginBottom: " 0.25rem",
        }}
      >
        {row.email}
      </span>
    </>
  );
}

function dateJoinedFormatter(cell: any, row: any) {
  const date = new Date(row.date_joined);
  return (
    <>
      <span style={{ fontWeight: "bolder" }}>
        {date.getMonth() + 1}/{date.getDate() + 1}
      </span>
      <span
        style={{
          color: "#99abaa",
          fontSize: ".8rem",
          lineHeight: "1rem",
          textTransform: "none",
          fontWeight: 300,
          marginBottom: " 0.25rem",
        }}
      >
        {date.getFullYear()}
      </span>
    </>
  );
}

function orderDateCreatedFormatter(cell: any, row: any) {
  const date = new Date(row.date_created);
  return (
    <>
      <span style={{ fontWeight: "bolder" }}>
        {date.getMonth() + 1}/{date.getDate()}
      </span>
      <span
        style={{
          color: "#99abaa",
          fontSize: ".8rem",
          lineHeight: "1rem",
          textTransform: "none",
          fontWeight: 300,
          marginBottom: " 0.25rem",
        }}
      >
        {date.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })}
      </span>
    </>
  );
}

function orderPriceNumberFormatter(cell: any, row: any) {
  return (
    <div>
      <h5>
        <Link to={`/admin/orders/${row.id}`}>{row.id}</Link>
      </h5>
      <span className="quiet">{row.total_str}</span>
    </div>
  );
}

function TicketNumberFormatter(cell: any, row: any) {
  return (
    <div>
      <h5>
        <Link to={`/tickets/${row.id}`} style={{ fontWeight: "bolder" }}>
          {row.number}
        </Link>
      </h5>
      <span className="quiet">{row.total_str}</span>
    </div>
  );
}

function TicketNumberClientFormatter(cell: any, row: any) {
  return (
    <div>
      <h5>
        <Link to={`/tickets/${row.id}`} style={{ fontWeight: "bolder" }}>
          {row.number}
        </Link>
      </h5>
      <span className="quiet">{row.total_str}</span>
    </div>
  );
}

function orderNumberFormatter(cell: any, row: any) {
  return (
    <Link to={`/admin/orders/${row.id}`} style={{ fontWeight: "bolder" }}>
      {row.number}
    </Link>
  );
}

function lastActivityFormatter(cell: any, row: any) {
  const date = new Date(row.last_login);
  const today = new Date(Date.now());
  const diff = today.getTime() - date.getTime();
  const dateDiff = new Date(diff);
  return (
    <>
      <span style={{ fontWeight: "bolder" }}>
        {date.getMonth() + 1}/{date.getDate()}/{date.getFullYear()}
      </span>
      <span
        style={{
          color: "#99abaa",
          fontSize: ".8rem",
          lineHeight: "1rem",
          textTransform: "none",
          fontWeight: 300,
          marginBottom: " 0.25rem",
        }}
      >
        {dateDiff.getDate()} days ago
      </span>
    </>
  );
}

function dateCreatedFormatter(cell: any, row: any) {
  const date = new Date(row.date_created);
  return (
    <span style={{ fontWeight: "bolder", paddingBottom: "30px" }}>
      {date.getMonth() + 1}/{date.getDate()}/{date.getFullYear()}
    </span>
  );
}

function DiscountFormatter(cell: any, row: any) {
  if (row?.discount_percent) {
    return <span style={{ fontWeight: "bolder" }}>{row.discount_percent}%</span>;
  } else if (row?.discount_amount) {
    return <span style={{ fontWeight: "bolder" }}>${row.discount_amount}</span>;
  }
}

function expirationDateFormatter(cell: any, row: any) {
  try{
    if(row?.expiration_date){
      const date = new Date(row.expiration_date);
      return (
        <span style={{ fontWeight: "bolder", paddingBottom: "30px" }}>
          {date.getMonth() + 1}/{date.getDate()}/{date.getFullYear()}
        </span>
      );
    }else {
      return <span style={{ fontWeight: "bolder" }}>never</span>;
    }
  }catch{
    return <span style={{ fontWeight: "bolder" }}>never</span>;
  }
}

function editPromoCodeFormatter(cell: any, row: any) {
  return <ButtonLink to={"/admin/promo-codes/edit/" + cell} text="Edit" />;
}

function lastPageFormater(cell: any, row: any) {
  var str = "";
  var host = "";
  if (row?.profile?.traffic) {
    str = (row?.profile?.traffic?.path as string)
    host = (row?.profile?.traffic?.hostname as string)
  }
  return (
    <>
      <span>
        <Link
          style={{
            color: "#007bff",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          to={""}
        >
          {str}
        </Link>
      </span>
      <br />
      <span
        style={{
          color: "#99abaa",
          fontSize: ".8rem",
          lineHeight: "1rem",
          textTransform: "none",
          fontWeight: 300,
          marginBottom: " 0.25rem",
        }}
      >
        {host}
      </span>
    </>
    // <a
    //   style={{
    //     color: "#007bff",
    //     textDecoration: "underline",
    //     cursor: "pointer",
    //   }}
    // >
    //   {row.profile.lastrequest}
    // </a>
  );
}

function genderFormatter(cell: any, row: any) {
  var str = "";
  if (row.profile.gender === "M") {
    str = "Male";
  }
  if (row.profile.gender === "F") {
    str = "Female";
  }
  if (row.profile.gender === "NB") {
    str = "Non-binary";
  }
  return <span>{str}</span>;
}

function eventFormatter(
  cell: any,
  row: any,
  rowIndex: any,
  formatExtraData: any
) {
  if (row.event) {
    return (
      <EventTile
        event={row.event}
        key={"EventTile" + row.event.id}
        user={formatExtraData.user}
      />
    );
  } else {
    return "No event found. It was likely deleted, and this is probably a test order.";
  }
}

function userFormatter(cell: any, row: any) {
  return (
    <div>
      <h5 style={{ margin: "0" }}>
        <Link
          style={{ color: "black", fontWeight: "500", textDecoration: "none" }}
          to={row?.purchaser?.pk ? `/admin/users/${row.purchaser.pk}` : ""}
        >
          {row.purchaser?.first_name} {row.purchaser?.last_name}
        </Link>
      </h5>
      <span className="quiet" style={{ fontSize: "12px", color: "gray" }}>
        {row.purchaser?.email}
      </span>
    </div>
  );
}

function userTicketFormatter(cell: any, row: any) {
  return (
    <div style={{ textAlign: "center" }}>
      <h5 style={{ margin: "0" }}>
        <Link
          style={{
            color: "#2c2f2f",
            fontWeight: "600",
            textDecoration: "none",
            textTransform: "uppercase",
            fontSize: "medium",
          }}
          to={row?.id ? `/admin/users/${row.id}` : ""}
        >
          {row?.first_name} {row?.last_name}
        </Link>
      </h5>
      <span className="quiet" style={{ fontSize: "12px", color: "gray" }}>
        {row?.email}
      </span>
    </div>
  );
}

function slashdateFormater(date: string) {
  const dates = date.split("-");
  const date2 = dates[1] + "/" + dates[2] + "/" + dates[0];
  return date2;
}

function shortslashdateFormater(date: string) {
  const dates = date.split("-");
  const date1 = dates[1] + "/" + dates[2];
  const date2 = dates[0];
  return (
    <div>
      <span
        style={{
          fontWeight: 600,
          fontSize: "medium",
          color: "#2c2f2f",
          alignSelf: "flex-end",
        }}
      >
        {date1}
      </span>
      <br />
      <span
        style={{
          fontWeight: 300,
          fontSize: "small",
          color: "#99abaa",
          alignSelf: "flex-end",
        }}
      >
        {date2}
      </span>
    </div>
  );
}

function totalFormatterTable(cell: any, row: any) {
  return (
    <table>
      <tbody>
        <tr className="total-table-subtotal-row">
          <th>Subtotal</th>
          <td>{row.subtotal_str}</td>
        </tr>
        <tr className="total-table-fee-row">
          <th>Fee</th>
          <td>{row.fee_str}</td>
        </tr>
        <tr className="total-table-total-row">
          <th>Total</th>
          <td>{row.total_str}</td>
        </tr>
      </tbody>
    </table>
  );
}

function totalFormatter(cell: any, row: any) {
  return <span style={{ fontWeight: "bolder" }}>{row.total_str}</span>;
}

function orderFormatter(cell: any, row: any) {
  return (
    <>
      <Link
        style={{
          textDecoration: "none",
          color: "#222222",
          fontWeight: "bolder",
          textTransform: "uppercase",
        }}
        to={
          row?.event?.id
            ? `${routes.ADMIN_ORDERS_BY_EVENT}/${row.event.id}`
            : ""
        }
      >
        {row?.event?.title ?? ""}
      </Link>
      {(row.status?.toLowerCase() === "completed" || row.status === 2) && (
        <span
          className="quiet success"
          style={{ fontSize: "12px", color: "gray" }}
        >
          <FaRegCheckCircle /> completed
        </span>
      )}
      {row.status === "pending" && (
        <span className="quiet" style={{ fontSize: "12px", color: "gray" }}>
          <FaRegCircle /> pending ({" "}
          {row.subtotal === "0.00"
            ? "free"
            : row.was_charged
            ? "charged"
            : " not charged"}{" "}
          )
        </span>
      )}
    </>
  );
}

function ticketsFormatter(cell: any, row: any) {
  return (
    <span style={{ fontWeight: "bolder" }}>{row.num_tickets_requested}</span>
  );
}

function mapFormatter(cell: any, row: any) {
  return (
    <GoogleMapComponentWithMarker
      googleMapURL={
        "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=" as any
      }
      loadingElement={(<div style={{ height: `100%` }} />) as any}
      containerElement={(<div style={{ height: `100%` }} />) as any}
      mapElement={
        (<div style={{ height: `150px`, marginBottom: `1rem` }} />) as any
      }
      venue={row as any}
    />
  );
}

export {
  moneyFormatter,
  booleanFormatter,
  quietFormatter,
  eventFormatter,
  userFormatter,
  totalFormatterTable,
  totalFormatter,
  orderFormatter,
  ticketsFormatter,
  mapFormatter,
  dateJoinedFormatter,
  birthdateFormatter,
  phoneFormatter,
  emailFormatter,
  detailsFormatter,
  genderFormatter,
  lastPageFormater,
  dateCreatedFormatter,
  expirationDateFormatter,
  editPromoCodeFormatter,
  lastActivityFormatter,
  orderDateCreatedFormatter,
  orderNumberFormatter,
  orderPriceNumberFormatter,
  DiscountFormatter,
  TicketNumberFormatter,
  userTicketFormatter,
  TicketNumberClientFormatter,
  slashdateFormater,
  shortslashdateFormater,
};
