import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import CountUp from "react-countup";

type Props = {
  overline: any;
  icon: any;
  valueType: any;
  value: any;
  valuePrefix: any;
  valueSuffix: any;
  underline: any
};

const MetricCode: React.FC<Props> = ({
  overline,
  icon,
  valueType,
  value,
  valuePrefix,
  valueSuffix,
  underline
}) => {
  return (
    <Col className="metric" br={10} style={{ borderRadius: 10, maxWidth: 'fit-content', minWidth: 161, minHeight: 84, padding: 15 }}>
      <span className="overline" style={{ textTransform: 'uppercase', fontWeight: 'bold', color: '#FFF', textAlign: 'left', fontSize: 20 }}>{overline}</span>
      {icon}
      {valueType === "number" ? (
        <div>
          <Row style={{ marginTop: 10 }}>
            <p style={{fontSize: 20}}>
              {valueSuffix}
            </p>
            <Row style={{opacity: 0.5, marginLeft: 15, fontSize: 12}}>
              <p>
                {console.log(value, valuePrefix)}
                ${value * valuePrefix}
              </p>
              <span className="underline" style={{ marginLeft: 5 }}>{underline}</span>
            </Row>
          </Row>

        </div>
      ) : (
        <div>
          <Col>
            {valueSuffix}
          </Col>
          <Col>
            {value * valuePrefix}
          </Col>
        </div>
      )}

    </Col>
  );
};

export default MetricCode;
