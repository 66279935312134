import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { NavItem } from "reactstrap";

type Props = {
  user: any;
  whiteLabel: any;
  toggleNavbar: any;
  isMobile:boolean
};

const ClientMenu: React.FC<Props> = ({
  user,
  whiteLabel,
  toggleNavbar,
  isMobile
}) => {
  return (
    <>
      {whiteLabel.id === 2 && whiteLabel.display_about_page && (
        <NavItem>
          <Link className="nav-link" to="/homepage" onClick={toggleNavbar}>
            Home
          </Link>
        </NavItem>
      )}

      {whiteLabel.id != 2 && (
        <NavItem>
          <Link className="nav-link" to="/" onClick={toggleNavbar}>
            Home
          </Link>
        </NavItem>
      )}

      {whiteLabel.id === 2 && (
        <NavItem>
          <a
            className="nav-link"
            href="https://primarychi.threadless.com"
            target="_blank"
            onClick={toggleNavbar}
          >
            Merch
          </a>
        </NavItem>
      )}

      {whiteLabel.id === 11 && (
        <NavItem>
          <a
            className="nav-link"
            href="https://organizedgrime.threadless.com"
            target="_blank"
            onClick={toggleNavbar}
          >
            Merch
          </a>
        </NavItem>
      )}

      <NavItem>
        <Link className="nav-link" to="/events" onClick={toggleNavbar}>
          Events
        </Link>
      </NavItem>

      {user && (
          <NavItem>
            <Link
                className="nav-link"
                to="/tickets"
                onClick={toggleNavbar}
            >
              Tickets
            </Link>
          </NavItem>
      )}
      {user && (
        <NavItem>
          <Link className="nav-link" to="/orders" onClick={toggleNavbar}>
            Orders
          </Link>
        </NavItem>
      )}

      {whiteLabel.id === "Two" && (
        <NavItem>
          <a
            className="nav-link"
            href="http://primarychi-explore-2.s3-website.us-east-2.amazonaws.com/"
            target="_blank"
            onClick={toggleNavbar}
          >
            Explore
          </a>
        </NavItem>
      )}

      {whiteLabel.id === 2 && (
        <NavItem>
          <a
            className="nav-link"
            href="https://primarychi.thebloxoffice.com/gallery"
            onClick={toggleNavbar}
          >
            Gallery
          </a>
        </NavItem>
      )}

      {user && (
        <NavItem>
          <Link
            className="nav-link"
            to="/change-profile"
            onClick={toggleNavbar}
          >
            Account
          </Link>
        </NavItem>
      )}
      
      {whiteLabel.id === 6 && (
        <NavItem>
          <Link className="nav-link" to="./home/index.html" onClick={toggleNavbar} target="_blank">
            Explore
          </Link>
        </NavItem>
      )}
      
      {whiteLabel.display_calendar_page && !isMobile &&(
        <NavItem>
          <Link
            className="nav-link"
            to="/events/calendar"
            onClick={toggleNavbar}
          >
            Calendar
          </Link>
        </NavItem>
      )}
      
      {whiteLabel.display_about_page && whiteLabel.id != 2 && (
        <NavItem>
          <Link className="nav-link" to="/about" onClick={toggleNavbar} target="_blank">
            About Us
          </Link>
        </NavItem>
      )}

      {whiteLabel.resident_artists?.length > 0 && (
        <NavItem>
          <Link className="nav-link" to="/artists" onClick={toggleNavbar}>
            {whiteLabel.id === 27 ? (
              <span>2019 Headliners</span>
            ) : (
              <span>Resident Artists</span>
            )}
          </Link>
        </NavItem>
      )}

      {whiteLabel.id === 2 && (
        <NavItem>
          <Link
            className="nav-link"
            to="/table-reservations"
            onClick={toggleNavbar}
          >
            Table Reservations
          </Link>
        </NavItem>
      )}

      {whiteLabel.display_contact_us_page && (
        <NavItem>
          <Link className="nav-link" to="/contact-us" onClick={toggleNavbar}>
            Contact Us
          </Link>
        </NavItem>
      )}

      {whiteLabel.id !== 2 && whiteLabel.display_table_reservations_page && (
        <NavItem>
          <Link
            className="nav-link"
            to="/table-reservations"
            onClick={toggleNavbar}
          >
            Table reservations
          </Link>
        </NavItem>
      )}
    </>
  );
};

export default ClientMenu;
