import React from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import {createFanCampaignResponse} from '../../Lib/RESTBlox'


class FanCampaignRequestModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: true,
      event: this.props.event,
      campaign: this.props.event.active_fan_campaigns[0],
      order: this.props.order,
      alert: {
        type: '',
        message: ''
      }
    };
    this.toggle = this.toggle.bind(this)
    this.skipCampaignHandler = this.skipCampaignHandler.bind(this)
    this.takeCampaignHandler = this.takeCampaignHandler.bind(this)
  }

  _isMounted = false

  toggle() {
    if (this._isMounted) {
      this.setState({
        modal: !this.state.modal
      });
    }
  }

  skipCampaignHandler() {
    var data = {
      declined: true,
    }
    createFanCampaignResponse(this.state.campaign.id, data)
      .then(data => {
        this.toggle()
      })
  }

  takeCampaignHandler() {
    var campaignUrl = "/fan-campaign/" + this.state.campaign.id + "/" + this.state.event.id + "/" + this.state.order.id
    this.props.history.push(campaignUrl)
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  render() {
    return (
      <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
        <ModalHeader toggle={this.toggle}>FanLink Survey</ModalHeader>
        <ModalBody>
          <p>Would you like to take a survey in exchange for a discount on your tickets?</p>
          <Row noGutters className="buttonRow">
            <Col className="pr-2">
              <Button
                className="hvr-grow-shadow"
                block
                color="secondary"
                onClick={this.skipCampaignHandler}
              >
                No thanks
              </Button>{' '}
            </Col>
            <Col className="p1-2">
              <Button
                className="hvr-grow-shadow"
                block
                color="primary"
                onClick={this.takeCampaignHandler}
              >
                Let's do it!
              </Button>{' '}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

export default FanCampaignRequestModal
