import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import AccountButtonGroup from '../Components/AccountButtonGroup'
import ProfileChangeForm from '../Components/Forms/ProfileChangeForm'

type Props = {
  user: any;
  postProfileChange: any;
};

const ProfileChange : React.FC<Props> = (props:Props) => {

    return (
      <div>
        <Row className="clearHeaderAndButtonGroup">
          <Col>
            <AccountButtonGroup/>
          </Col>
        </Row>
        <Row>
          <Col>
            <ProfileChangeForm user={props.user} postProfileChange={props.postProfileChange}/>
          </Col>
        </Row>
      </div>
    );
}

export default ProfileChange;
