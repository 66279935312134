import axios from 'axios';

// import {bloxTrack} from './RESTTrack'

var APIBaseURL = 'https://sendy.thebloxoffice.com/'

// INTERNAL METHODS
function fetchBloxSendyEndpoint(endpoint:string, method:string, data={}, condition:boolean) {
  return new Promise(function(resolve, reject) {
    var dataURL = APIBaseURL + endpoint

    // Handle the request
    axios({
      method: method,
      url: dataURL,
      data: data,
      config: { headers: {'Content-Type': 'multipart/form-data', 'Authorization': 'Basic SFyQoXlBxEvHmbd6yMhM'} }
    }as any)
      .then(response => {
        console.info("      Sucessful Sendy API request (" + method + ") to " + endpoint)
        resolve(response)
      })
      .catch(err => {
        console.error("BloxOffice Sendy API Error (" + endpoint + "):\n", err.response)
        reject(err)
      })
  });
}

function subscribe(userName:string, userEmail:string, listId:string) {
  var endpoint = "subscribe"
  var bodyFormData = new FormData();
  bodyFormData.append('name', userName);
  bodyFormData.append('email', userEmail);
  bodyFormData.append('list', listId);
  bodyFormData.append('boolean', 'true');

  console.log(bodyFormData)
  return new Promise(function(resolve, reject) {
    fetchBloxSendyEndpoint(endpoint, 'post', bodyFormData, false)
      .then(data => resolve(data))
      .catch(err => {
        reject(err)
      })
  });
}

export {
  subscribe
}
