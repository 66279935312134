import React, { Component } from "react";
import { getAdminPromoCodes } from "../../Lib/RESTBlox";
import AdminLayout from "../../Layouts/AdminLayout";
import SmartTable from "./components/SmartTable/SmartTable";
import {
  dateCreatedFormatter,
  expirationDateFormatter,
  editPromoCodeFormatter,
  DiscountFormatter
} from "../../Lib/TableFormatters";

class PromoCodeList extends Component {
  render() {
    const columns = [
      {
        dataField: "code",
        text: "Code",
        sort: true,
        classes: "code-col",
        align: "left",
      },
      {
        dataField: "discount_amount",
        text: "Discount",
        sort: false,
        classes: "discount-col right-align",
        // headerClasses: "discount-col right-align",
        formatter: DiscountFormatter,
        align: "left",
      },
      {
        dataField: "number_available",
        text: "Quantity",
        sort: true,
        classes: "quantity-col right-align",
        headerClasses: "quantity-col right-align",
        align: "center",
      },
      {
        dataField: "number_used",
        text: "Orders Used",
        sort: true,
        classes: "used-col right-align",
        headerClasses: "used-col right-align",
        align: "center",
      },
      {
        dataField: "tickets_used_on",
        text: "Tickets Used",
        sort: true,
        classes: "used-col right-align",
        headerClasses: "used-col right-align",
        align: "center",
      },
      {
        dataField: "date_created",
        text: "Created",
        sort: true,
        classes: "date-created-col",
        formatter: dateCreatedFormatter,
        align: "left",
      },
      {
        dataField: "expiration_date",
        text: "Expires",
        sort: true,
        classes: "date-expires-col",
        formatter: expirationDateFormatter,
        align: "left",
      },
      {
        dataField: "id",
        text: "Edit",
        sort: false,
        classes: "edit-col",
        formatter: editPromoCodeFormatter,
        align: "left",
      },
    ];

    return (
      <AdminLayout title="Promo codes">
        <div className="BloxAdminContentContainer">
          <SmartTable
            columns={columns}
            loadData={getAdminPromoCodes}
            objectTitle="Promo codes"
            search
          />
        </div>
      </AdminLayout>
    );
  }
}

export default PromoCodeList;
